import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

// Quality cards data

// import CardData from "../utilities/qualitiesText";

const Qualities = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "Me/ghr_2.png" }) {
        id
        childImageSharp {
          fluid(quality: 100, maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const [handleAnimations, setHandleAnimations] = useState(false);

  // Intersection observer that set's the inView state when 15% of the referenced element is hidden/insight.

  const { ref, inView } = useInView({
    threshold: 0.15,
    // delay: 100,
  });

  // Handle triggering animations.

  useEffect(() => {
    if (inView) {
      setHandleAnimations(true);
    }
  }, [handleAnimations, inView]);

  return (
    <section ref={ref} id="qualities-section">
      <div className="hero-heading-box">
        <h2 className={handleAnimations ? "hero-heading-smaller" : "unactive"}>
          Quali-
        </h2>
        <h2
          className={
            handleAnimations ? "hero-heading-smaller part-two" : "unactive"
          }
        >
          ties
        </h2>
      </div>
      {/* desktop heading */}
      <div className="hero-heading-box-desktop">
        <h2 className={handleAnimations ? "hero-heading-smaller" : "unactive"}>
          Qualities
        </h2>
      </div>
      <div className="qualities-content">
        <div className="intro-content">
          <div className={handleAnimations ? "qualities-txt" : "unactive"}>
            <h2 className="main-heading">
              "When I do things I like to do them well and I don’t mind getting
              my hands dirty to get them right."
            </h2>
          </div>
          <Img
            fluid={data.image.childImageSharp.fluid}
            className={handleAnimations ? "ghr-img" : "unactive"}
          />
        </div>
        <div
          className={
            handleAnimations ? "qualities-cards-container" : "unactive"
          }
        >
          {/* {CardData.qualitiesCardsText.map((data) => (
            <div className="qualities-card" >
              <h3 className="scndry-heading">{data.heading}</h3>
              <p className="main-text">{data.info}</p>
            </div>
          ))} */}
          <div className="qualities-card">
            <h3 className="scndry-heading">Creative</h3>
            <p className="main-text">
              I’ve been involved in creative activities since I was a kid. From
              making short films, being in bands, to drawing and making street
              art.
            </p>
          </div>
          <div className="qualities-card">
            <h3 className="scndry-heading">Design</h3>
            <p className="main-text">
              I’ve enjoyed drawing since I was a kid, so I have a good sense of
              aesthetics. I consider myself a good designer though I have plenty
              of room to grow.
            </p>
          </div>
          <div className="qualities-card">
            <h3 className="scndry-heading">Team player</h3>
            <p className="main-text">
              I enjoy being a part of a team and contributing to a collective
              effort.
            </p>
          </div>
          <div className="qualities-card">
            <h3 className="scndry-heading">Quality</h3>
            <p className="main-text">
              When I do things I like to do them well and I don’t mind getting
              my hands dirty to get them right.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualities;
